import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`query fighterList {
  images: allFile(
    filter: { relativeDirectory: { eq: "fighters" } }
    sort: { fields: name }
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            quality: 95
            formats: [AUTO, WEBP, AVIF]
          )
        }
        base
      }
    }
  }
}`
//            style={{ marginBottom: `1.45rem` }}

const FightersPage = ({data}) => {
  const images = data.images.edges;

  const fighterList = {
    "amandaMarie.jpg": {
      name: "Amanda Marie", alias: "Mizz",
      summary: "Edgy, quick-witted, with a side of cuteness, Mizz Amanda Marie's rebellious attitude  gives her the courage to take on any girl."
    },
    "ashleyWildcat.jpg": {
      name: "Ashley Wildcat", alias: "The Wildcat",
      summary: "This sexy wildcat lives up to her name. Ashley pounces and loves toying with her prey. The original Knockout Cat."
    },
    "becca.jpg": {
      name: "Becca", alias: "The Sweetheart",
      summary: "She's arguably the cutest living creature on earth, and this makes Becca a prime target for the other ladies. All that beauty is bound to evoke jealousy, I guess."
    },
    "bellaInk.jpg": {
      name: "Bella Ink", alias: "Wild Thing",
      summary: "Sexy Bella is one bad ass bitch that's out to show these girls who's boss. She's a wild one."
    },
    "dominaLunaVera.jpg": {
      name: "Luna", alias: "Domina",
      summary: "Spunky Luna is ready to face any of these girls, but that same drive can get her cute little ass in trouble!"
    },
    "madisonSwan.jpg": {
      name: "Madison Swan", alias: "Gym Rat",
      summary: "Madison's washboard abs are enough to intimidate her opponents at first sight. Just wait 'til they feel her true strength."
    },
    "maryjane.jpg": {
      name: "Maryjane", alias: "G.I.",
      summary: "New to the fight scene, Maryjane's hopeful spirit may have gotten her in over her head. On the bright side, she looks damn hot getting beat!"
    },
    "mistyLovelace.jpg": {
      name: "Misty Lovelace", alias: "Penthouse Pet",
      summary: "Nothing hotter than a centerfold turned fighter. Misty brings her own sexy fighting style to Knockout Cats."
    },
    "morganDelRay.jpg": {
      name: "Morgan Del Ray", alias: "Hottie Next Door",
      summary: "Morgan's got that bright-eyed outlook and loveable attitude, all packaged up in one truly killer body."
    },
    "nikkiNext.jpg": {
      name: "Nikki Next", alias: "Session Queen",
      summary: "Don't let her cute looks fool you. She's beaten up the boys, and now, Nikki's teaching the girls what it's like to be squeezed by her perfect pair of legs."
    },
    "sashaFae.jpg": {
      name: "Sasha Fae", alias: "Bondage Babe",
      summary: "Sasha doesn't just want to beat her opponents, she wants them to be bound and begging... or the other way around. That's cool with her too."
    },
    "veronikaValentine.jpg": {
      name: "Veronika Valentine", alias: "Fet Vet",
      summary: "Veronika knows what it takes to win a fight. She makes it look easy (and gorgeous) when she destroys a girl."
    },
  };


  return (
    <Layout subtitle="Fighters" isWide={true}>
      <Seo title="Fighters" />

      <p>
Knockout Cats has been fortunate enough to work with some of the most beautiful and talented young ladies in the biz. They consistantly impress me by their stellar performances and professional attitudes while acting out the sexy, cute, strong, fiesty, and/or innocent characters that you've come to love. Be sure to always show them respect for the work that they do. These ladies definitely deserve it!
      </p>

      <div className="center">
        <h2>[Individual fighter pages are in the works!]</h2>
      </div>

      <div className="vid-list">

        {images.map( ({node}, index) => {
          const fighter = fighterList[node.base];

          return (
            <article className="vid-item" key={fighter.name}>
              <GatsbyImage image={getImage(node.childImageSharp)} alt={fighter.name} />
              <div className="vid-text">
                <div className="vid-sku">{fighter.alias}</div>
                <header>
                  <h3 className="vid-title">{fighter.name}</h3>
                </header>
                <p>{fighter.summary}</p>
              </div>
            </article>
          );
        })}

      </div>

    </Layout>
  )
}

export default FightersPage
